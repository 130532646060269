import React from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { LandingSection } from "../../shared/landing-section/LandingSection";
import MetaTags from "react-meta-tags";

const useStyles = makeStyles()((theme) => ({
  privacyPolicy: {
    margin: "10vh 10vw",
  },
  title: { marginBottom: "2vh" },
  subTitle: { marginBottom: "4vh" },
  listItem: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    margin: "2vh",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.body5.fontSize,
      fontWeight: theme.typography.body5.fontWeight,
    },
  },
}));

interface PrivacyPolicyPageProps {}

export const PrivacyPolicyPage: React.FC<PrivacyPolicyPageProps> = () => {
  const { classes } = useStyles();
  const { palette } = useTheme();

  return (
    <>
      <MetaTags>
        <meta name="robots" content="noindex" />
      </MetaTags>
      <LandingSection title="Our Privacy Policy" gradient={palette.gradient4} />
      <div className={classes.privacyPolicy}>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Introduction
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          This Privacy Policy explains how LinkRiver and its subsidiaries (if
          applicable) (“LinkRiver") collect, use, and disclose information that
          you provide to us including information that allows us to determine
          your identity (“Personal Data”). The use of our website and services
          by you is subject to the terms of this Privacy Policy. We intend to
          introduce you to how your Personal Data is processed and used by
          LinkRiver. This Privacy Policy explains the principles we adhere to
          while processing your Personal Data.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Treatment of Personal Data
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          We at LinkRiver do our best to keep your Personal Data private and
          secure, do not use your Personal Data unlawfully and at your request,
          disclose complete information to you on how your Personal Data is
          being processed.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Collection of your Personal Data
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          We collect your Personal Data through two primary sources: 1. directly
          when you fill out a contact form on our website and 2. indirectly when
          you fill out the external Typeform questionnaire created by us. You
          may learn more about your Personal Data protection rights under the
          Typeform Privacy Policy{" "}
          <a
            href="https://www.typeform.com/help/a/gdpr-rights-for-respondents-360029615691/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          . We collect the following Personal Data from you:
        </Typography>
        <ul className={classes.listItem}>
          <li>Your name and/or legal entity name</li>
          <li>Private and/or business e-mail</li>
          <li>Your project name and scope</li>
          <li>Project plans</li>
        </ul>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          We also collect standard website data such as log and device data
          automatically gathered by our server.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Purposes for collecting your Personal Data
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          We collect your Personal Data only for rendering our services to you
          and for all other related actions, such as storing and processing your
          Personal Data internally with LinkRiver employees.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Data security
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          LinkRiver takes reasonable technical and organizational measures to
          safeguard Personal Data against unauthorized access.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Your rights
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          Apart from the treatment of your Personal Data by LinkRiver described
          above, you are authorized to request of us information with respect to
          the use of your Personal Data and we are obliged to supply this
          information to you. Furthermore, we are authorized to use the Personal
          Data only if a specific contractual and/or relevant legal basis exist.
          You, as a data subject, can receive the following information:
        </Typography>
        <ul className={classes.listItem}>
          <li>Which of your data is being processed</li>
          <li>The purpose of the data processing</li>
          <li>Legal basis for the data processing</li>
          <li>How the data was processed</li>
          <li>Who the data was transferred to</li>
          <li>Ground and purpose of Data release</li>
        </ul>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          You can request a copy of Information processed by LinkRiver. You are
          authorized to require adjustment, updating, addition, blockage,
          deletion or destruction of your Personal Data if itappears to be
          incomplete, incorrect, out-of-date or if the process of information
          gathering and processing is carried out illegally. We observe the
          requirements of the applicable legislation, which may prevent us from
          an immediate deletion of your Personal Data.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Cross-border transfers
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          Your Personal Data may be stored and processed in any country where
          LinkRiver and its third-party service providers conduct business.
          These locations may be outside of your country of residence, where
          different data protection laws may apply. When we transfer Personal
          Data, we implement safeguards for protection of the transferred
          Personal Data through contractual clauses.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Use of our website by minors
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          Services offered on our website are not directed to individuals 16
          years of age or younger, or those not of legal age in the relevant
          jurisdiction, and we request that these individuals, or others on
          their behalf, do not provide us with their information.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Retention period
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          We retain your information for the period necessary to fulfill the
          purposes outlined in this Privacy Policy unless a longer retention
          period is required or not prohibited by applicable law. If we have a
          contractual relationship with you, we keep your Personal Data
          throughout the whole term of service provided to you and for 5 years
          from the completion of the service for thefollowing reasons:
        </Typography>
        <ul className={classes.listItem}>
          <li>To respond to any questions and complaints</li>
          <li>To show that we treated you fairly</li>
        </ul>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          We may keep your Personal Data for over 5 years if we are not able to
          delete it for legal grounds.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          How to withdraw your consent
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          You can withdraw your consent at any time and we will stop processing
          your Personal Data unless we are obliged to do so under applicable
          legislation. Please contact us if you want to do so. If you withdraw
          your consent, we may not be able to provide services to you.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Changes to the Privacy Policy
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          We may amend this Privacy Policy to remain compliant with any changes
          in law and/or to reflect how our business processes Personal Data.
          This version was created on November 08, 2022. Please visit our
          website from time to time to stay up-to-date with our Privacy Policy.
        </Typography>
        <Typography
          sx={{ typography: { sm: "h5", xs: "h8" } }}
          className={classes.title}
        >
          Contact us
        </Typography>
        <Typography
          sx={{ typography: { sm: "body1", xs: "body5" } }}
          className={classes.subTitle}
        >
          In case of questions regarding this Privacy Policy, you can reach us
          at: privacy@linkriver.io
        </Typography>
      </div>
    </>
  );
};
