import React from "react";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";
import { ServiceSectionSegment } from "./service-section-segment/ServiceSectionSegment";
import { AnimateComponentOnScroll } from "../../../shared/animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  serviceSection: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 auto 10vh",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    height: "100%",

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    width: "100vw",
    margin: "10vh auto",
    marginBottom: "5vh",
    [theme.breakpoints.up("xxxl")]: {
      fontSize: "90px",
    },
  },
}));

interface ServiceSectionProps {}

export const ServiceSection: React.FC<ServiceSectionProps> = () => {
  const { classes } = useStyles();
  return (
    <div id="services" className={classes.serviceSection}>
      <Typography className={classes.title} variant={"h2"}>
        Our Services
      </Typography>

      <AnimateComponentOnScroll enterDirection="left" width="100%">
        <ServiceSectionSegment
          title="Node Monitoring Service"
          link="https://nodemonitoring.io"
          image="service-section-first"
          reversed={true}
          newSide
        >
          An all-in-one monitoring platform for Chainlink node operators,
          offering streamlined configuration, customized dashboards, and
          cutting-edge alerting solutions
        </ServiceSectionSegment>
      </AnimateComponentOnScroll>

      {/* <AnimateComponentOnScroll enterDirection="right" width="100%">
        <ServiceSectionSegment
          title="NodeLogs"
          link="https://nodelogs.link/"
          newSide
          image="service-section-third"
        >
          A debugging platform for Chainlink node operators powered by a
          Chainlink grant. From the community for the community.
        </ServiceSectionSegment>
      </AnimateComponentOnScroll> */}
    </div>
  );
};
